import { DadosClientes4Component } from './paginas/dados-clientes4/dados-clientes4.component';
import { DadosClientes1Component } from './paginas/dados-clientes1/dados-clientes1.component';
import { DadosClientes2Component } from './paginas/dados-clientes2/dados-clientes2.component';
import { DadosClientes3Component } from './paginas/dados-clientes3/dados-clientes3.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: "", component: DadosClientes1Component },
  { path: "dados1/vendedor/:vendedor", component: DadosClientes1Component },
  { path: "dados1/:cpf", component: DadosClientes1Component },
  { path: "dados2/:id", component: DadosClientes2Component },
  { path: "dados3/:id", component: DadosClientes3Component },
  { path: "dados4/:id", component: DadosClientes4Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
