<nav class="navbar navbar-expand-lg navbar-dark nav-conf pt-0 pb-0 shadow">
  <a class="navbar-brand" (click)="inicio()">
    <img src="http://fideliza.gmpti.com/assets/fideliza.png" width="120px" class="d-inline-block align-top">
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleNavbar = !toggleNavbar" data-toggle="collapse"
    data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse div-nav" id="navbarColor01" [ngbCollapse]="toggleNavbar">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <!--<a class="nav-link" href="#">Envio de Documentos</a>!-->
      </li>
    </ul>
  </div>
</nav>
