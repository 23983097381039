import { DadosClientes1Service } from './dados-clientes1.service';
import { estadoCivil } from './estadoCivil.model';
import { Sexo } from './sexo.model';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { DadosClientes1 } from './dados-clientes1.model';

@Component({
  selector: 'app-dados-clientes1',
  templateUrl: './dados-clientes1.component.html',
  styleUrls: ['./dados-clientes1.component.css']
})
export class DadosClientes1Component implements OnInit {

  public form: FormGroup;
  public MASKS = MASKS;
  public sexo: Sexo[];
  public estadoCivil: estadoCivil[];
  public cpf: string;
  public vendedor: string;
  //selectedSexo: Sexo = { id: null, nome: null };

  constructor(private router: Router, private fb: FormBuilder, private service: DadosClientes1Service, private route: ActivatedRoute) {

  }

  createForm(cliente: DadosClientes1) {
    this.form = this.fb.group({
      id: [cliente.id],
      nome: [cliente.nome, Validators.compose([
        Validators.minLength(3),
        Validators.maxLength(100),
        Validators.required
      ])],
      cpf: [cliente.cpf, Validators.compose([
        Validators.minLength(14),
        Validators.maxLength(14),
        Validators.required,
        NgBrazilValidators.cpf
      ])],
      data_nascimento: [cliente.data_nascimento, Validators.compose([
        Validators.minLength(10),
        Validators.required
      ])],
      whatsapp: [cliente.whatsapp, Validators.compose([
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(15),
        NgBrazilValidators.telefone
      ])],
      valor_desejado: [cliente.valor_desejado, Validators.compose([
        Validators.required
      ])],
      rg: [cliente.rg, Validators.compose([
        Validators.maxLength(15),
        Validators.required
      ])],
      data_expedicao: [cliente.data_expedicao, Validators.required],
      orgao_expedidor: [cliente.orgao_expedidor, Validators.compose([
        Validators.maxLength(6),
        Validators.required
      ])],
      nome_pai: [cliente.nome_pai, Validators.maxLength(100)],
      nome_mae: [cliente.nome_mae, Validators.maxLength(100)],
      id_sexo: [cliente.id_sexo, Validators.required],
      modalidade: [cliente.modalidade, Validators.required],
      naturalidade: [cliente.naturalidade, Validators.maxLength(100)],
      id_estado_civil: [cliente.id_estado_civil, Validators.required],
      id_vendedor: [cliente.id_vendedor],
      nome_vendedor: [cliente.nome_vendedor]
    });
  }

  ngOnInit(): void {
    this.createForm(new DadosClientes1());

    this.service.getSexo().subscribe((sexo: Sexo[]) => {
      this.sexo = sexo;
    })

    this.service.getEstadoCivil().subscribe((estadoCivil: estadoCivil[]) => {
      this.estadoCivil = estadoCivil;
    })

    this.route.params.subscribe(params => this.cpf = params['cpf']);
    if (this.cpf)
      this.buscaDadosCliente();

    //desenvolvimento
    /*if (document.location.href.split('/')[5]) {
      this.vendedor = document.location.href.split('/')[5].split('V')[1];
      this.buscaVendedor();
    }*/

    //produção
    if (document.location.href.split('/')[6]) {
      this.vendedor = document.location.href.split('/')[6].split('V')[1];
      this.buscaVendedor();
    }

  }

  buscaVendedor() {
    //this.vendedor = '5';
    //if (this.vendedor) {
    this.service.buscaVendedor(this.vendedor).subscribe((vendedor: any) => {
      //console.log(vendedor);
      if (vendedor.id) {
        this.form.controls["id_vendedor"].setValue(vendedor.id);
        this.form.controls["nome_vendedor"].setValue(vendedor.nome);
      }
    });
    //}
  }

  onSubmit(): void {
    if (this.form.valid) {
      if (!this.form.get("id").value) {
        this.service.insere(this.form.value).subscribe((cliente: DadosClientes1) => {
          if (cliente.id)
            this.router.navigate(["/dados2/" + cliente.id]);
          else
            console.log(cliente);
        })
      } else {
        this.service.altera(this.form.value).subscribe((cliente: boolean) => {
          if (cliente)
            this.router.navigate(["/dados2/" + this.form.get("id").value]);
          else
            console.log(cliente);
        })
      }
    } else {
      Object.keys(this.form.controls).forEach(campo => {
        const controle = this.form.get(campo);
        controle.markAsDirty();
      });
    }
  }

  buscaDadosCliente() {
    if (this.form.get("cpf").value || this.cpf) {
      let campoCpf;
      if (this.form.get("cpf").value)
        campoCpf = this.form.get("cpf").value;
      else
        campoCpf = this.cpf;

      this.service.buscaCliente(campoCpf.replace(/[^0-9]/g, '')).subscribe((cliente: DadosClientes1) => {
        if (cliente.id) {
          this.form.controls["id"].setValue(cliente.id);
          this.form.controls["nome"].setValue(cliente.nome);
          this.form.controls["data_nascimento"].setValue(cliente.data_nascimento);
          this.form.controls["whatsapp"].setValue(cliente.whatsapp);
          this.form.controls["valor_desejado"].setValue(cliente.valor_desejado);
          this.form.controls["rg"].setValue(cliente.rg);
          this.form.controls["data_expedicao"].setValue(cliente.data_expedicao);
          this.form.controls["orgao_expedidor"].setValue(cliente.orgao_expedidor);
          this.form.controls["nome_pai"].setValue(cliente.nome_pai);
          this.form.controls["nome_mae"].setValue(cliente.nome_mae);
          this.form.controls["id_sexo"].setValue(cliente.id_sexo);
          this.form.controls["modalidade"].setValue(cliente.modalidade);
          this.form.controls["naturalidade"].setValue(cliente.naturalidade);
          this.form.controls["id_estado_civil"].setValue(cliente.id_estado_civil);
          this.form.controls["cpf"].setValue(cliente.cpf);
          this.form.controls["id_vendedor"].setValue(cliente.id_vendedor);
          this.form.controls["nome_vendedor"].setValue(cliente.nome_vendedor);
        }
      });
    }
  }
}
