import { DadosClientes3Service } from './dados-clientes3.service';
import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { DadosClientes3 } from './dados-clientes3.model';
import { Estados } from '../dados-clientes2/estados.model';


@Component({
  selector: 'app-dados-clientes3',
  templateUrl: './dados-clientes3.component.html',
  styleUrls: ['./dados-clientes3.component.css']
})
export class DadosClientes3Component implements OnInit {

  public form: FormGroup;
  public MASKS = MASKS;
  public uf: Estados[];
  public id: string;
  public colorConta: string = "#000000";
  public colorPreto: string = "#000000";
  public colorErro: string = "crimson";

  constructor(private router: Router, private fb: FormBuilder, private service: DadosClientes3Service, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.createForm(new DadosClientes3());

    this.route.params.subscribe(params => this.id = params['id']);

    if (this.id)
      this.buscaDadosCliente(this.id);

    this.service.buscaEstados().subscribe((uf: Estados[]) => {
      this.uf = uf;
    })
  }

  somenteNumero(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }


  buscaDadosCliente(id: string) {
    this.service.buscaCliente(id).subscribe((cliente: DadosClientes3) => {
      if (cliente.id) {
        this.form.controls["id"].setValue(cliente.id);
        this.form.controls["profissao"].setValue(cliente.profissao);
        this.form.controls["salario"].setValue(cliente.salario);
        this.form.controls["dtAdmissao"].setValue(cliente.dtAdmissao);
        this.form.controls["idEmpresa"].setValue(cliente.idEmpresa);
        this.form.controls["empresa"].setValue(cliente.empresa);
        this.form.controls["telefone"].setValue(cliente.telefone);
        this.form.controls["matricula"].setValue(cliente.matricula);
        this.form.controls["cnpj"].setValue(cliente.cnpj);
        this.form.controls["endereco"].setValue(cliente.endereco);
        this.form.controls["bairro"].setValue(cliente.bairro);
        this.form.controls["cidade"].setValue(cliente.cidade);
        this.form.controls["uf"].setValue(cliente.uf);
        this.form.controls["tpConta"].setValue(cliente.tpConta);
        this.form.controls["idBanco"].setValue(cliente.idBanco);
        this.form.controls["dtAbertura"].setValue(cliente.dtAbertura);
        this.form.controls["agencia"].setValue(cliente.agencia);
        this.form.controls["dgAgencia"].setValue(cliente.dgAgencia);
        this.form.controls["conta"].setValue(cliente.conta);
        this.form.controls["dgConta"].setValue(cliente.dgConta);
        this.form.controls["operacao"].setValue(cliente.operacao);
        this.form.controls["banco"].setValue(cliente.banco);
      }
    });
  }

  createForm(cliente: DadosClientes3) {
    this.form = this.fb.group({
      id: [cliente.id],
      profissao: [cliente.profissao, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])],
      salario: [cliente.salario, Validators.required],
      dtAdmissao: [cliente.dtAdmissao, Validators.compose([
        Validators.required,
        Validators.minLength(10)
      ])],
      idEmpresa: [cliente.idEmpresa],
      empresa: [cliente.empresa, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])],
      telefone: [cliente.telefone, NgBrazilValidators.telefone],
      matricula: [cliente.matricula, Validators.maxLength(100)],
      cnpj: [cliente.cnpj, Validators.maxLength(18)],
      endereco: [cliente.endereco, Validators.maxLength(40)],
      bairro: [cliente.bairro, Validators.maxLength(40)],
      cidade: [cliente.cidade, Validators.maxLength(40)],
      uf: [cliente.uf],
      tpConta: [cliente.tpConta, Validators.required],
      idBanco: [cliente.idBanco],
      banco: [cliente.banco, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ])],
      dtAbertura: [cliente.dtAbertura, Validators.minLength(10)],
      agencia: [cliente.agencia, Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(4)
      ])],
      dgAgencia: [cliente.dgAgencia, Validators.maxLength(1)],
      conta: [cliente.conta, Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20)
      ])],
      dgConta: [cliente.dgConta, Validators.compose([
        Validators.maxLength(1),
        Validators.required
      ])],
      operacao: [cliente.operacao]
    })
  }

  anterior(): void {
    this.router.navigate(["dados2/" + this.id]);
  }

  onSubmit(): void {

    if (this.form.valid) {
      console.log(this.form.value);
      this.service.atualiza(this.form.value).subscribe((cliente: boolean) => {
        this.router.navigate(["dados4/" + this.form.get('id').value]);
      })

    } else {
      Object.keys(this.form.controls).forEach(campo => {
        const controle = this.form.get(campo);
        controle.markAsDirty();
      });

      this.validaValor();
    }
  }

  validaValor(): void {
    if (this.form.get('tpConta').value == null && !this.form.get('tpConta').pristine) {
      this.colorConta = this.colorErro;
    } else {
      this.colorConta = this.colorPreto;
    }
  }

}
