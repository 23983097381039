import { Estados } from './estados.model';
import { DadosClientes2Service } from './dados-clientes2.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgControl } from '@angular/forms';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { DadosClientes2 } from './dados-clientes2.model';
import { Endereco, ErroCep, NgxViacepService } from '@brunoc/ngx-viacep';

@Component({
  selector: 'app-dados-clientes2',
  templateUrl: './dados-clientes2.component.html',
  styleUrls: ['./dados-clientes2.component.css']
})
export class DadosClientes2Component implements OnInit {

  public form: FormGroup;
  public MASKS = MASKS;
  public colorCartao: string = "#000000";
  public colorVeiculo: string = "#000000";
  public colorImovel: string = "#000000";
  public colorPreto: string = "#000000";
  public colorErro: string = "crimson";
  public uf: Estados[];
  public id: string;
  private el: NgControl;


  constructor(private router: Router, private fb: FormBuilder, private service: DadosClientes2Service, private viacep: NgxViacepService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.createForm(new DadosClientes2());

    this.route.params.subscribe(params => this.id = params['id']);

    if (this.id)
      this.buscaDadosCliente(this.id);

    this.service.buscaEstados().subscribe((uf: Estados[]) => {
      this.uf = uf;
    })
  }

  somenteNumero(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  buscaDadosCliente(id: string) {
    this.service.buscaCliente(id).subscribe((cliente: Array<any>) => {
      if (cliente[0].id) {
        this.form.controls["id"].setValue(cliente[0].id);
        this.form.controls["cpf"].setValue(cliente[0].cpf);
        this.form.controls["endereco"].setValue(cliente[0].endereco);
        this.form.controls["cep"].setValue(cliente[0].cep);
        this.form.controls["numero"].setValue(cliente[0].numero);
        this.form.controls["complemento"].setValue(cliente[0].complemento);
        this.form.controls["bairro"].setValue(cliente[0].bairro);
        this.form.controls["cidade"].setValue(cliente[0].cidade);
        this.form.controls["uf"].setValue(cliente[0].uf);
        this.form.controls["telefone"].setValue(cliente[0].telefone);
        this.form.controls["email"].setValue(cliente[0].email);
        this.form.controls["qtde_dependente"].setValue(cliente[0].qtde_dependente);

        this.form.controls["possuiCartaoCredito"].setValue(cliente[0].possuiCartaoCredito);
        this.form.controls["possuiVeiculo"].setValue(cliente[0].possuiVeiculo);
        this.form.controls["possuiImovel"].setValue(cliente[0].possuiImovel);

        if (cliente[1]) {
          this.form.controls["idReferencia1"].setValue(cliente[1].id);
          this.form.controls["nomeReferencia1"].setValue(cliente[1].nome);
          this.form.controls["telefoneReferencia1"].setValue(cliente[1].telefone);
        }

        if (cliente[2]) {
          this.form.controls["idReferencia2"].setValue(cliente[2].id);
          this.form.controls["nomeReferencia2"].setValue(cliente[2].nome);
          this.form.controls["telefoneReferencia2"].setValue(cliente[2].telefone);
        }

      }
    });
  }


  createForm(cliente: DadosClientes2) {
    this.form = this.fb.group({
      id: [cliente.id],
      cpf: [cliente.cpf],
      possuiCartaoCredito: [cliente.possuiCartaoCredito, Validators.required],
      possuiImovel: [cliente.possuiImovel, Validators.required],
      possuiVeiculo: [cliente.possuiVeiculo, Validators.required],
      cep: [cliente.cep, Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        NgBrazilValidators.cep
      ])],
      endereco: [cliente.endereco, Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])],
      numero: [cliente.numero, Validators.compose([
        Validators.required,
        Validators.maxLength(10)
      ])],
      complemento: [cliente.complemento],
      bairro: [cliente.bairro, Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])],
      cidade: [cliente.cidade, Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])],
      uf: [cliente.uf, Validators.required],
      email: [cliente.email, Validators.maxLength(100)],
      telefone: [cliente.telefone, Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(15),
        NgBrazilValidators.telefone
      ])],
      qtde_dependente: [cliente.qtde_dependente, Validators.compose([
        Validators.required,
        Validators.maxLength(2)
      ])],
      idReferencia1: [cliente.id],
      telefoneReferencia1: [cliente.telefoneReferencia1, Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(15),
        NgBrazilValidators.telefone
      ])],
      nomeReferencia1: [cliente.nomeReferencia1, Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])],
      idReferencia2: [cliente.id],
      telefoneReferencia2: [cliente.telefoneReferencia2, Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(15),
        NgBrazilValidators.telefone
      ])],
      nomeReferencia2: [cliente.nomeReferencia2, Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])],
    });
  }


  onSubmit(): void {
    if (this.form.valid) {
      this.service.atualiza(this.form.value).subscribe((cliente: boolean) => {
        this.router.navigate(["dados3/" + this.form.get('id').value]);
      })

    } else {
      Object.keys(this.form.controls).forEach(campo => {
        const controle = this.form.get(campo);
        controle.markAsDirty();
      });

      this.validaValor();

    }
  }

  validaValor(): void {

    if (this.form.get('possuiCartaoCredito').value == null && !this.form.get('possuiCartaoCredito').pristine) {
      this.colorCartao = this.colorErro;
    } else {
      this.colorCartao = this.colorPreto;
    }

    if (this.form.get('possuiImovel').value == null && !this.form.get('possuiImovel').pristine) {
      this.colorImovel = this.colorErro;
    } else {
      this.colorImovel = this.colorPreto;
    }

    if (this.form.get('possuiVeiculo').value == null && !this.form.get('possuiVeiculo').pristine) {
      this.colorVeiculo = this.colorErro;
    } else {
      this.colorVeiculo = this.colorPreto;
    }
  }

  buscaCep() {
    this.viacep.buscarPorCep(this.form.get("cep").value).then((endereco: Endereco) => {
      // Endereço retornado :)
      if (!endereco.logradouro) {
        document.getElementById("endereco").focus();
      } else {
        document.getElementById("numero").focus();
      }
      this.form.controls["endereco"].setValue(endereco.logradouro);
      this.form.controls["bairro"].setValue(endereco.bairro);
      this.form.controls["cidade"].setValue(endereco.localidade);
      this.form.controls["uf"].setValue(endereco.uf);

    }).catch((error: ErroCep) => {
      // Alguma coisa deu errado :/
      console.log(error.message);
    });
  }


  anterior(): void {
    this.router.navigate(["dados1/" + this.form.get('cpf').value]);

  }

}
