export class DadosClientes2 {
    id: number;
    possuiCartaoCredito: string;
    possuiImovel: string;
    possuiVeiculo: string;
    cep: string;
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    uf: string;
    telefone: string;
    email: string;
    idReferencia1: number;
    telefoneReferencia1: string;
    nomeReferencia1: string;
    idReferencia2: number;
    telefoneReferencia2: string;
    nomeReferencia2: string;
    qtde_dependente: number;
    cpf: string;
}