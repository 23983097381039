
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgBrazil } from 'ng-brazil'
import { TextMaskModule } from 'angular2-text-mask';
import { NgxViacepModule } from '@brunoc/ngx-viacep';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopoComponent } from './shared/topo/topo.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DadosClientes1Component } from './paginas/dados-clientes1/dados-clientes1.component';
import { DadosClientes2Component } from './paginas/dados-clientes2/dados-clientes2.component';
import { DadosClientes3Component } from './paginas/dados-clientes3/dados-clientes3.component';
import { DadosClientes4Component } from './paginas/dados-clientes4/dados-clientes4.component';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    AppComponent,
    TopoComponent,
    DadosClientes1Component,
    DadosClientes2Component,
    DadosClientes3Component,
    DadosClientes4Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    TextMaskModule,
    NgBrazil,
    NgxViacepModule,
    CurrencyMaskModule
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
