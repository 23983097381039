<div class="container mt-5 mb-5">
  <div class="card shadow">
    <div class="card-header">
      Dados do Cliente
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="card-body">

        <div class="form-row">
          <div class="form-group col-md-4">
            <label [ngStyle]="{'color': colorCartao}">Você Possui Cartão de Crédito?</label><br>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="cartaoNao" name="possuiCartaoCredito" class="custom-control-input" value="0" formControlName="possuiCartaoCredito" (change)="validaValor()">
              <label class="custom-control-label" for="cartaoNao">Não</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="cartaoSim" name="possuiCartaoCredito" class="custom-control-input" value="1" formControlName="possuiCartaoCredito" (change)="validaValor()">
              <label class="custom-control-label" for="cartaoSim">Sim</label>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label [ngStyle]="{'color': colorVeiculo}">Você Possui Veículo Próprio?</label><br>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="veiculoNao" name="possuiVeiculo" class="custom-control-input" value="0" formControlName="possuiVeiculo" (change)="validaValor()">
              <label class="custom-control-label" for="veiculoNao">Não</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="veiculoSim" name="possuiVeiculo" class="custom-control-input" value="1" formControlName="possuiVeiculo" (change)="validaValor()">
              <label class="custom-control-label" for="veiculoSim">Sim</label>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label [ngStyle]="{'color': colorImovel}">Você Possui Imóvel Próprio?</label><br>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="imovelNao" name="possuiImovel" class="custom-control-input" value="0" formControlName="possuiImovel" (change)="validaValor()">
              <label class="custom-control-label" for="imovelNao">Não</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="imovelSim" name="possuiImovel" class="custom-control-input" value="1" formControlName="possuiImovel" (change)="validaValor()">
              <label class="custom-control-label" for="imovelSim">Sim</label>
            </div>
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="cep">CEP</label>
            <input type="text" class="form-control" id="cep" name="cep" placeholder="CEP" formControlName="cep" [textMask]="{mask: MASKS.cep.textMask}" (blur)="buscaCep()">
          </div>
          <div class="form-group col-md-6">
            <label for="logradouro">Logradouro</label>
            <input type="text" class="form-control" id="logradouro" name="logradouro" placeholder="Logradouro" formControlName="endereco">
          </div>
          <div class="form-group col-md-3">
            <label for="numero">Número</label>
            <input type="text" class="form-control" id="numero" name="numero" placeholder="Número" formControlName="numero" (keypress)="somenteNumero($event)">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-2">
            <label for="complemento">Complemento</label>
            <input type="text" class="form-control" id="complemento" name="complemento" placeholder="Complemento" formControlName="complemento">
          </div>
          <div class="form-group col-md-4">
            <label for="bairro">Bairro</label>
            <input type="text" class="form-control" id="bairro" name="bairro" placeholder="Bairro" formControlName="bairro">
          </div>
          <div class="form-group col-md-4">
            <label for="cidade">Cidade</label>
            <input type="text" class="form-control" id="cidade" name="cidade" placeholder="Cidade" formControlName="cidade">
          </div>
          <div class="form-group col-md-2">
            <label for="uf">UF</label>
            <select class="custom-select" name="uf" id="uf" formControlName="uf">
              <option selected>Selecione a UF</option>
              <option *ngFor="let uf of uf" value="{{uf.Sigla}}">{{uf.Sigla}}</option>
            </select>
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="telefone">Telefone/Celular</label>
            <input type="text" class="form-control" id="telefone" name="telefone" placeholder="Telefone" formControlName="telefone" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
          </div>
          <div class="form-group col-md-6">
            <label for="email">Email</label>
            <input type="text" class="form-control" id="email" name="email" placeholder="Email" formControlName="email">
          </div>
          <div class="form-group col-md-3">
            <label for="filhos">Número de Dependentes (Filhos)</label>
            <input type="text" class="form-control" id="filhos" name="filhos" placeholder="Número de Dependentes (Filhos)" formControlName="qtde_dependente" (keypress)="somenteNumero($event)">
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="form-group col-md-2">
            <label for="telefoneReferencia1">Telefone Referência</label>
            <input type="text" class="form-control" id="telefoneReferencia1" name="telefoneReferencia1" placeholder="Telefone" formControlName="telefoneReferencia1"
            [textMask]="{mask: MASKS.telefone.textMaskFunction}">
          </div>
          <div class="form-group col-md-4">
            <label for="nomeReferencia1">Nome Refência</label>
            <input type="text" class="form-control" id="nomeReferencia1" name="nomeReferencia1" placeholder="Nome Refêrencia" formControlName="nomeReferencia1">
          </div>
          <div class="form-group col-md-2">
            <label for="telefoneReferencia2">Telefone Referência</label>
            <input type="text" class="form-control" id="telefoneReferencia2" name="telefoneReferencia2" placeholder="Telefone Referência"
            formControlName="telefoneReferencia2" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
          </div>
          <div class="form-group col-md-4">
            <label for="nomeReferencia2">Nome Refêrencia</label>
            <input type="text" class="form-control" id="nomeReferencia2" name="nomeReferencia2" placeholder="Nome Refêrencia" formControlName="nomeReferencia2">
          </div>
        </div>

        <div class="w-100 text-center mt-4 mb-4">
          <button type="button" class="btn btn-secondary width_btn" (click)="anterior()">Anterior</button>
          <button type="submit" class="btn btn-success ml-1 width_btn">Próximo</button>
        </div>
      </div>
    </form>
  </div>
</div>
