<div class="container mt-5 mb-5">
    <div class="card shadow">
      <div class="card-header">
        Dados do Cliente
      </div>
      <!--<form >!-->
        <div class="card-body">
              Recebemos seus dados e iremos analisar, em breve entraremos em contato.
              
              Obrigado!
        </div>
      <!--</form>!-->
    </div>
  </div>
  