export class DadosClientes1 {
    id: number;
    nome: string;
    cpf: string;
    data_nascimento: string;
    whatsapp: string;
    modalidade: string;
    valor_desejado: number;
    rg: string;
    data_expedicao: string;
    orgao_expedidor: string;
    nome_pai: string;
    nome_mae: string;
    naturalidade: string;
    id_estado_civil: number;
    id_sexo: number;
    id_vendedor: number;
    nome_vendedor: string;
}