import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topo',
  templateUrl: './topo.component.html',
  styleUrls: ['./topo.component.css']
})
export class TopoComponent implements OnInit {

  toggleNavbar = true;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  inicio(): void {
    this.router.navigate(["/"]);
  }
}
