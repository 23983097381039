import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dados-clientes4',
  templateUrl: './dados-clientes4.component.html',
  styleUrls: ['./dados-clientes4.component.css']
})
export class DadosClientes4Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
