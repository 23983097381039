<div class="container mt-5 mb-5">
  <div class="card shadow">
    <div class="card-header">
      Dados do Cliente
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="card-body">

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="nome">Nome</label>
            <input type="text" class="form-control" formControlName="nome" id="nome" name="nome" maxlength="100">
          </div>
          <div class=" form-group col-md-3">
            <label for="cpf">CPF</label>
            <input type="text" class="form-control" id="cpf" name="cpf" formControlName="cpf"
              [textMask]="{mask: MASKS.cpf.textMask}" (blur)="buscaDadosCliente()">
          </div>
          <div class=" form-group col-md-3">
            <label for="data_nascimento">Dt. de Nascimento</label>
            <input type="text" class="form-control" id="data_nascimento" name="data_nascimento"
              formControlName="data_nascimento" [textMask]="{mask: MASKS.data.textMask}">
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="whatsapp">Whatsapp</label>
            <input type="text" class="form-control" id="whatsapp" name="whatsapp" formControlName="whatsapp"
              [textMask]="{mask: MASKS.telefone.textMaskFunction}">
          </div>
          <div class="form-group col-md-3">
            <label for="valor_desejado">Valor Desejado</label>
            <input type="text" class="form-control" id="valor_desejado" name="valor_desejado"
              formControlName="valor_desejado" currencyMask>
          </div>
          <div class="form-group col-md-6">
            <label for="modalidade">Tipo de Renda</label>
            <select class="custom-select" name="modalidade" id="modalidade" formControlName="modalidade">
              <option value="AS">Assalariado (carteira assinada)</option>
              <option value="FP">Funcionário Público (Ativo/Inativo)</option>
              <option value="PL">Autônomo/Profissional Liberal</option>
              <option value="AP">Aposentado/Pensionista INSS</option>
              <option value="BL">Benefic. LOAS/BPC/Amparo Social</option>
              <option value="BA">Benific. Auxílio Doença</option>
            </select>
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="rg">RG</label>
            <input type="text" class="form-control" id="rg" name="rg" formControlName="rg">
          </div>
          <div class="form-group col-md-3">
            <label for="data_expedicao">Dt. Emissão</label>
            <input type="text" class="form-control" id="data_expedicao" name="data_expedicao"
              formControlName="data_expedicao" [textMask]="{mask: MASKS.data.textMask}">
          </div>
          <div class="form-group col-md-3">
            <label for="orgao_expedidor">Órgao Expeditor</label>
            <input type="text" class="form-control" id="orgao_expedidor" name="orgao_expedidor"
              formControlName="orgao_expedidor" maxlength="6">
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="nome_pai">Nome do Pai</label>
            <input type="text" class="form-control" id="nome_pai" name="nome_pai" formControlName="nome_pai"
              maxlength="100">
          </div>
          <div class="form-group col-md-6">
            <label for="nome_mae">Nome da Mãe</label>
            <input type="text" class="form-control" id="nome_mae" name="nome_mae" formControlName="nome_mae"
              maxlength="100">
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="naturalidade">Qual Cidade Você Nasceu?</label>
            <input type="text" class="form-control" id="naturalidade" name="naturalidade" formControlName="naturalidade"
              maxlength="100">
          </div>
          <div class="form-group col-md-3">
            <label for="id_estado_civil">Estado Civil</label>
            <select class="custom-select" name="id_estado_civil" id="id_estado_civil" formControlName="id_estado_civil">
              <option *ngFor="let estadoCivil of estadoCivil" value="{{estadoCivil.id}}">{{estadoCivil.descricao}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-3">
            <label for="id_sexo">Sexo</label>
            <select class="custom-select" name="id_sexo" id="id_sexo" formControlName="id_sexo">
              <option *ngFor="let sexo of sexo" value="{{sexo.id}}">{{sexo.nome}}</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="Vendedor">Vendedor</label>
            <input type="text" class="form-control" id="Vendedor" name="Vendedor" formControlName="nome_vendedor" disabled
              maxlength="100">
          </div>
        </div>
        <div class="w-100 text-center mt-4 mb-4">
          <button type="submit" class="btn btn-success w-50">Próximo</button>
        </div>
      </div>
    </form>
  </div>
</div>
