import { Estados } from './estados.model';
import { DadosClientes2 } from './dados-clientes2.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DadosClientes2Service {

    private url = environment.bancoDados;

    constructor(private http: HttpClient) { }

    atualiza(cliente: DadosClientes2): Observable<boolean> {
        return this.http.put<boolean>(this.url + "cliente/update2/" + cliente.id, JSON.stringify(cliente)).pipe(
            retry(2),
            catchError(this.handleError)
        );
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Erro ocorreu no lado do client
            errorMessage = error.error.message;
        } else {
            // Erro ocorreu no lado do servidor
            errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    };

    buscaEstados(): Observable<Estados[]> {
        return this.http.get<Estados[]>('assets/estados.json');
    }

    buscaCliente(id: string): Observable<Array<any>> {
        return this.http.get<Array<any>>(this.url + "cliente/id/" + id).pipe(
            retry(2),
            catchError(this.handleError)
        );
    }
}
