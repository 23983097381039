export class DadosClientes3 {
    id: number;
    profissao: string;
    salario: string;
    dtAdmissao: string;
    idEmpresa: number;
    empresa: string;
    telefone: string;
    matricula: string;
    cnpj: string;
    endereco: string;
    bairro: string;
    cidade: string;
    uf: string;
    tpConta: number;
    idBanco: number;
    banco: string;
    dtAbertura: string;
    agencia: string;
    dgAgencia: string;
    conta: string;
    dgConta: number;
    operacao: string;
}