import { DadosClientes1 } from './dados-clientes1.model';
import { estadoCivil } from './estadoCivil.model';
import { Sexo } from './sexo.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DadosClientes1Service {

  private url = environment.bancoDados;


  constructor(private http: HttpClient) { }

  getSexo(): Observable<Sexo[]> {
    return this.http.get<Sexo[]>(this.url + "sexo").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getEstadoCivil(): Observable<estadoCivil[]> {
    return this.http.get<estadoCivil[]>(this.url + "estadoCivil").pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  insere(cliente: DadosClientes1): Observable<DadosClientes1> {
    return this.http.post<DadosClientes1>(this.url + "cliente", JSON.stringify(cliente)).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  altera(cliente: DadosClientes1): Observable<boolean> {
    return this.http.put<boolean>(this.url + "cliente/update/" + cliente.id, JSON.stringify(cliente)).pipe(
      retry(2),
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  };

  buscaCliente(cpf: string): Observable<DadosClientes1> {
    return this.http.get<DadosClientes1>(this.url + "cliente/cpf/" + cpf).pipe(
      retry(2),
      catchError(this.handleError)
    );
  }

  buscaVendedor(id: string): Observable<any> {
    return this.http.get<any>(this.url + "vendedor/" + id).pipe(
      retry(2),
      catchError(this.handleError)
    );
  }
}
