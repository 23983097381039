<div class="container mt-5 mb-5">
  <div class="card shadow">
    <div class="card-header">
      Dados do Cliente
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="card-body">

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="cargo">Profissão</label>
            <input type="text" class="form-control" id="profissao" name="profissao" placeholder="Profissão" formControlName="profissao">
          </div>
          <div class="form-group col-md-3">
            <label for="salario">Salário/Renda</label>
            <input type="text" class="form-control" id="salario" name="salario" placeholder="Salário/Renda" formControlName="salario"  currencyMask>
          </div>
          <div class="form-group col-md-3">
            <label for="dtAdmissao">Dt. de Admissão</label>
            <input type="text" class="form-control" id="dtAdmissao" name="dtAdmissao" placeholder="Dt. de Admissão" formControlName="dtAdmissao" [textMask]="{mask: MASKS.data.textMask}">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="empresa">Empresa</label>
            <input type="text" class="form-control" id="empresa" name="empresa" placeholder="Empresa" formControlName="empresa">
          </div>
          <div class="form-group col-md-3">
            <label for="telefoneEmpresa">Telefone</label>
            <input type="text" class="form-control" id="telefoneEmpresa" name="telefoneEmpresa" placeholder="Telefone" formControlName="telefone" [textMask]="{mask: MASKS.telefone.textMaskFunction}">
          </div>
          <div class="form-group col-md-3">
            <label for="matricula">Matrícula</label>
            <input type="text" class="form-control" id="matricula" name="matricula" placeholder="Matrícula" formControlName="matricula">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-5">
            <label for="cnpj">CNPJ</label>
            <input type="text" class="form-control" id="cnpj" name="cnpj" placeholder="CNPJ" formControlName="cnpj" [textMask]="{mask: MASKS.cnpj.textMask}">
          </div>
          <div class="form-group col-md-7">
            <label for="logradouro">Endereço, Nº</label>
            <input type="text" class="form-control" id="logradouro" name="logradouro" placeholder="Endereço, Nº" formControlName="endereco">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-5">
            <label for="bairro">Bairro</label>
            <input type="text" class="form-control" id="bairro" name="bairro" placeholder="Bairro" formControlName="bairro">
          </div>
          <div class="form-group col-md-5">
            <label for="cidade">Cidade</label>
            <input type="text" class="form-control" id="cidade" name="cidade" placeholder="Cidade" formControlName="cidade">
          </div>
          <div class="form-group col-md-2">
            <label for="uf">UF</label>
            <select class="custom-select" name="uf" id="uf" formControlName="uf">
              <option selected>Selecione a UF</option>
              <option *ngFor="let uf of uf" value="{{uf.Sigla}}">{{uf.Sigla}}</option>
            </select>
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label [ngStyle]="{'color': colorConta}">Tipo de Conta Bancária</label><br>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="corrente" name="tpConta" class="custom-control-input" value="1" formControlName="tpConta" (change)="validaValor()">
              <label class="custom-control-label" for="corrente">Corrente</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="poupanca" name="tpConta" class="custom-control-input" value="2" formControlName="tpConta" (change)="validaValor()">
              <label class="custom-control-label" for="poupanca">Poupança</label>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="banco">Banco</label>
            <input type="text" class="form-control" id="banco" name="banco" placeholder="Banco" formControlName="banco">
          </div>
          <div class="form-group col-md-3">
            <label for="dtAbertura">Data da Abertura</label>
            <input type="text" class="form-control" id="dtAbertura" name="dtAbertura" placeholder="Data da Abertura" formControlName="dtAbertura" [textMask]="{mask: MASKS.data.textMask}">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="agencia">Agência</label>
            <input type="number" class="form-control" id="agencia" name="agencia" placeholder="Agência" formControlName="agencia" (keypress)="somenteNumero($event)">
          </div>
          <div class="form-group col-md-1">
            <label for="dgAgencia">Dígito</label>
            <input type="text" class="form-control" id="dgAgencia" name="dgAgencia" placeholder="Dígito" formControlName="dgAgencia">
          </div>
          <div class="form-group col-md-4">
            <label for="conta">Conta</label>
            <input type="text" class="form-control" id="conta" name="conta" placeholder="Conta" formControlName="conta" (keypress)="somenteNumero($event)">
          </div>
          <div class="form-group col-md-1">
            <label for="dgConta">Dígito</label>
            <input type="text" class="form-control" id="dgConta" name="dgConta" placeholder="Dígito" formControlName="dgConta">
          </div>
          <div class="form-group col-md-3">
            <label for="operacao">Operação</label>
            <input type="text" class="form-control" id="operacao" name="operacao" placeholder="Operação, caso exista" formControlName="operacao">
          </div>
        </div>

        <div class="w-100 text-center mt-4 mb-4">
          <button type="button" class="btn btn-secondary width_btn" (click)="anterior()">Anterior</button>
          <button type="submit" class="btn btn-success ml-1 width_btn">Próximo</button>
        </div>
      </div>
    </form>
  </div>
</div>
