import { DadosClientes3 } from './dados-clientes3.model';
import { Estados } from './../dados-clientes2/estados.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DadosClientes3Service {

  private url = environment.bancoDados;

  constructor(private http: HttpClient) { }

  buscaEstados(): Observable<Estados[]> {
    return this.http.get<Estados[]>('assets/estados.json');
  }

  buscaCliente(id: string): Observable<DadosClientes3> {
    return this.http.get<DadosClientes3>(this.url + "cliente/id2/" + id).pipe(
      retry(2),
      catchError(this.handleError)
    );
  }

  atualiza(cliente: DadosClientes3): Observable<boolean> {
    return this.http.put<boolean>(this.url + "cliente/update3", JSON.stringify(cliente)).pipe(
      retry(2),
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  };
}
